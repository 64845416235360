@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
  
.fade-in-up {
  animation-name: fade-in-up;
}

.animated {
  animation-duration: 0.75s;
  animation-fill-mode: both;
}

/* Base styles */
body {
  background-color: var(--eggshell);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.25;
}

h1,
h2,
h3 {
  font-weight: 300;
}

h4,
h5 {
  font-weight: 700;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

a {
  transition: all 0.15s ease-in;
}

a:hover,
a:focus {
  transition: color 0.15s ease-in;
}

p {
  line-height: 1.5;
}

img {
  /* height: auto; */
  max-width: 100%;
  vertical-align: middle;
}
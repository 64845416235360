/*

   SKINS
   Docs: http://tachyons.io/docs/themes/skins/

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will 
   be set to the current text color. 

*/

.color-inherit {
  color: inherit;
}

/* Background colors */

.bg-eggshell {
  background-color: var(--eggshell);
}

.bg-michigan-blue {
  background-color: var(--michigan-blue);
}

.bg-michigan-maize {
  background-color: var(--michigan-maize);
}

.bg-navy-two {
  background-color: var(--navy-two);
}

.bg-white {
  background-color: var(--white);
}

.bg-rich-black {
  background-color: var(--rich-black);
}

.bg-transparent {
  background-color: var(--transparent);
}

.bg-inherit {
  background-color: inherit;
}

.bg-dark-opacity {
  background-color: rgba(0, 24, 61, 0.72);
}


@media (--breakpoint-not-small) {
  .bg-michigan-blue-ns {
    background-color: var(--michigan-blue);
  }
  .bg-transparent-ns {
    background-color: var(--transparent);
  }
}

@media (--breakpoint-large) {
  .bg-michigan-blue-l {
    background-color: var(--michigan-blue);
  }
}

/*

  STYLES

  Add custom styles here.

*/
/* purgecss start ignore */

/* What markdown is spitting out */
.content section main h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-weight: 700;
}

.content .well main h3 {
  margin-top: 2.75rem;
}
.content section main details div,
.content section main ul {
  line-height: 1.5;
}

.content section main details div p:first-child {
  margin-top: 0;
}
@media (--breakpoint-not-small) {
  .content section.well main p,
  .content section.well main ul,
  .content section.well main ol {
    width: 85%;
  }
}

.content section.well main li {
  margin-bottom: 0.5rem;
}

.content section.well main li:last-child {
  margin-bottom: 2rem;
}

.content section.well blockquote {
  border-left: 0;
  border-top: 4px solid var(--arboretum-blue);
  border-bottom: 4px solid var(--arboretum-blue);
  color: var(--rich-black);
  font-weight: 400;
  max-width: 70%;
  width: 100%;
}

.content section.well blockquote p {
  color: var(--rich-black);
  font-size: 2.25rem;
  width: 100%;
}

.content .well main p a,
.content .well main ul a,
.content .well main ol a,
.content .well main h1 a,
.content .well main h2 a,
.content .well main h3 a,
.content .well main h4 a,
.content .well main h5 a {
  border-bottom: 1px solid var(--blue-two);
  color: var(--blue-two);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.content .well main p a:hover,
.content .well main ul a:hover,
.content .well main ol a:hover,
.content .well main h1 a:hover,
.content .well main h2 a:hover,
.content .well main h3 a:hover,
.content .well main h4 a:hover,
.content .well main h5 a:hover {
  border-bottom: 1px solid var(--michigan-blue);
  color: var(--michigan-blue);
}

.content .well main table a {
  border-bottom-color: transparent;
  color: var(--michigan-blue);
}
.content .well main table a:hover {
  color: var(--blue-two);
}

.content section main table {
  background-color: var(--white);
  border-collapse: collapse;
}

.content section main table td,
.content section main table th {
  border: 1px solid var(--rich-black);
  padding: 0.5rem;
}

.content section main dl {
  overflow: scroll;
}

/* Details */

details summary::-webkit-details-marker {
  display: none;
}

summary {
  display: block;
  list-style-image: none;
}

details[open] summary::after {
  content: url(/img/caret.svg);
  transform: rotate(90deg);
  transform-origin: center center;
  top: 22px;
}

details summary::after {
  content: url(/img/caret.svg);
  display: block;
  height: 14px;
  position: absolute;
  top: 22px;
  transition: all 0.2s ease-in-out;
  right: 1rem;
  width: 7px;
}

details[open] summary ~ * {
  animation: sweep 0.5s ease-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Line on side of lead text */

.los span {
  display: inline-block;
  position: relative;
}
.los span:before,
.los span:after {
  content: "";
  position: absolute;
  height: 20px;
  border-bottom: 1px solid var(--michigan-maize);
  top: 0;
  width: 80px;
}
.los span:before {
  right: 100%;
  margin-right: 15px;
}
.los span:after {
  left: 100%;
  margin-left: 15px;
}

@media (--breakpoint-not-small) {
  .los span:before,
  .los span:after {
    width: 210px;
  }
}

/* Nav links */

nav li a span:after {
  border-bottom: 1px solid var(--michigan-maize);
  bottom: -22px;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition: all 0.15s ease-in-out;
  width: 100%;
}

nav li a span:hover:after {
  border-bottom: 5px solid var(--michigan-maize);
  opacity: 1;
}

.is-active a span:after {
  border-bottom: 5px solid var(--michigan-maize);
  opacity: 1;
}

/* SVG hover */

.svg-hover-michigan-maize svg g {
  transition: all 0.15s ease-in-out;
}
.svg-hover-michigan-maize svg:hover g {
  stroke: var(--michigan-maize);
}
/* Sidebar display */
.sidebar ul li {
  display: none;
}

.sidebar ul li.active .sub-menu .sub-menu {
  display: none;
}

.sidebar ul li.active,
.sidebar ul li.active ul.sub-menu li,
.sidebar ul li.active ul.sub-menu li.active .sub-menu,
.sidebar ul li.active ul.sub-menu li.active .sub-menu li {
  display: block;
}

/* Sidebar hovers */
.sub-menu li {
  & a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    background-color: var(--michigan-blue);
    transition: all 0.15s ease;
  }
  & a:hover:after {
    width: 100%;
  }
}

/* Sidebar actives */
.sub-menu li.active .sub-menu li {
  & a:after {
    width: 0;
  }
}
.sub-menu li.active,
.sub-menu li.active .sub-menu li.active {
  & a:after {
    width: 100%;
  }
}

.sub-menu li.active .sub-menu li {
  & a:hover:after,
  & a:focus:after {
    width: 100%;
  }
}

.lsd {
  list-style-type: disc;
}

.fs15 {
  font-size: 0.9375rem;
}

/* Sidebar height fix */
.sidebar {
  height: fit-content;
}

/* Navigation */

.primary-navigation {
  width: 280px;
  transform: translateX(-280px);
  position: absolute;
  height: 100%;
  transition: transform 0.15s ease-in;
}

.primary-navigation.open {
  transform: translateX(0);
}

@media (--breakpoint-large) {
  .primary-navigation {
    width: 100%;
    position: relative;
    transform: none;
  }

  .button {
    display: inline-block;
    font-size: 1rem;
  }

  .footer-menu .menu-item {
    margin-right: 0;
  }
}

/* Hamburger */
.hamburger {
  padding: 10px 0 0 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 1rem 0 0;
  overflow: visible;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--white);
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: var(--white);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/* Columns */
.col2 {
  columns: 2;
}

@media (--breakpoint-medium) {
  .col2-m {
    columns: 2;
  }
  .col3-m {
    columns: 3;
  }
}

/* Text shadows */
.text-shadow-1 {
  text-shadow: 0px 0px 12px rgba(var(--rich-black-rgb), var(--alpha-5));
}
/* purgecss end ignore */

.grid {
  display: grid;
}

.gc-4 {
  grid-column: span 4;
}

.gc-6 {
  grid-column: span 6;
}

.gc-7 {
  grid-column: span 7;
}

.gc-12 {
  grid-column: span 12;
}

.gtc-3 {
  grid-template-columns: repeat(3, 1fr);
}

.gtc-12 {
  grid-template-columns: repeat(12, 1fr);
}

.gcp-0625 {
  grid-column-gap: 0.625rem;
}

@media (--breakpoint-not-small) {
  .gtc-3-ns {
    grid-template-columns: repeat(3, 1fr);
  }
  .gc-4-ns {
    grid-column: span 4;
  }
  .gc-6-ns {
    grid-column: span 6;
  }
  .gcp-125-ns {
    grid-column-gap: 1.25rem;
  }
}

@media (--breakpoint-medium) {
  .gc-4-m {
    grid-column: span 4;
  }
  .gc-6-m {
    grid-column: span 6;
  }
  .gc-12-m {
    grid-column: span 12;
  }
}

@media (--breakpoint-large) {
  .gc-4-l {
    grid-column: span 4;
  }
  .gc-6-l {
    grid-column: span 6;
  }
}

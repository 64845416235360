/* 
  
   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.
 
 */

.hover-michigan-maize:hover,
.hover-michigan-maize:focus {
  color: var(--michigan-maize);
}

.hover-bg-michigan-maize:hover,
.hover-bg-michigan-maize:focus {
  background-color: var(--michigan-maize);
}

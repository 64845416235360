/* Imports */
@import "imports/imports.css";

/* purgecss ignore start */
html.sr .load-hidden {
  visibility: hidden;
}

body {
  visibility: visible;
}

.object-cover {
  object-fit: cover;
}
/* purgecss ignore end */
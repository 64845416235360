/*

   Tachyons
   COLOR VARIABLES

   Grayscale
   - Solids
   - Transparencies
   Colors

*/

:root {
  --michigan-blue: #00274c;
  --michigan-maize: #ffcb05;
  --arboretum-blue: #2f65a7;
  --blue-two: #0F5887;
  --white: #ffffff;
  --eggshell: #f6f6f6;
  --rich-black: #1A222B;
  --rich-black-rgb: 26,34,43;
  --dark-grey: #2d2f30;
  --medium-grey: #464849;
  --light-grey: #606263;
  --tappan-red: #9a3324;
  --taubman-teal: #00b2a9;
  --ross-orange: #d86018;
  --a2-amethyst: #702082;
  --navy: #001338;
  --navy-two: #00183d;
  --transparent: transparent;
  --mustard: #e0b400;
  --alpha-5: 0.5;
}

/*

   POSITIONING
   Docs: http://tachyons.io/docs/layout/position/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.static { position: static; }
.relative  { position: relative; }
.absolute  { position: absolute; }
.fixed  { position: fixed; }
.sticky { position: sticky; }

.right-10 { right: 10px; }
.top-5 { top: 5px; }
.top4 { top: 4rem; }

@media (--breakpoint-not-small) {
  .static-ns { position: static; }
  .relative-ns  { position: relative; }
  .absolute-ns  { position: absolute; }
  .fixed-ns  { position: fixed; }
  .sticky-ns { position: sticky; }
}

@media (--breakpoint-medium) {
  .static-m { position: static; }
  .relative-m  { position: relative; }
  .absolute-m  { position: absolute; }
  .fixed-m  { position: fixed; }
  .sticky-m { position: sticky; }
}

@media (--breakpoint-large) {
  .static-l { position: static; }
  .relative-l  { position: relative; }
  .absolute-l  { position: absolute; }
  .fixed-l  { position: fixed; }
  .sticky-l { position: sticky; }
}

/*

   VISIBILITY

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


/*
    Text that is hidden but accessible
    Ref: http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/

.clip {
  position: fixed !important;
  _position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.focusable:focus {
  clip: auto;
  position: relative !important;
}

@media (--breakpoint-not-small) {
  .clip-ns {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
}

@media (--breakpoint-medium) {
  .clip-m {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
}

@media (--breakpoint-large) {
  .clip-l {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
}


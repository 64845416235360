/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/

.link {
  text-decoration: none;
  transition: color .15s ease-in;
}

.link:link,
.link:visited {
  transition: color .15s ease-in;
}
.link:hover   {
  transition: color .15s ease-in;
}
.link:active  {
  transition: color .15s ease-in;
}
.link:focus   {
  transition: color .15s ease-in;
  outline: 1px dotted currentColor;
}

/* Text colors */
.michigan-blue {
  color: var(--michigan-blue);
}

.michigan-maize {
  color: var(--michigan-maize);
}

.white {
  color: var(--white);
}

.rich-black {
  color: var(--rich-black);
}

.red {
  color: var(--tappan-red);
}

/* Text hovers */

.hover-michigan-blue:hover {
  color: var(--michigan-blue);
}

.hover-michigan-maize:hover {
  color: var(--michigan-maize);
}

.hover-white:hover {
  color: var(--white);
}

.hover-rich-black:hover {
  color: var(--rich-black);
}
/*

   FONT FAMILY GROUPS
   Docs: http://tachyons.io/docs/typography/font-family/

*/
@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("./fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* montserrat-300 - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("./fonts/montserrat-v12-latin_latin-ext-300.woff2") format("woff2"),
    url("./fonts/montserrat-v12-latin_latin-ext-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
/* montserrat-regular - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("./fonts/montserrat-v12-latin_latin-ext-regular.woff2") format("woff2"),
    url("./fonts/montserrat-v12-latin_latin-ext-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* montserrat-italic - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Italic"), local("Montserrat-Italic"),
    url("./fonts/montserrat-v12-latin_latin-ext-italic.woff2") format("woff2"),
    url("./fonts/montserrat-v12-latin_latin-ext-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
/* montserrat-700 - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("./fonts/montserrat-v12-latin_latin-ext-700.woff2") format("woff2"),
    url("./fonts/montserrat-v12-latin_latin-ext-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* montserrat-800 - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("./fonts/montserrat-v12-latin_latin-ext-800.woff2") format("woff2"),
    url("./fonts/montserrat-v12-latin_latin-ext-800.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

.sans-serif {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "avenir next",
    avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.display-font {
  font-family: "Bebas Neue", -apple-system, BlinkMacSystemFont, "avenir next",
    avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.serif {
  font-family: georgia, times, serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}

/* Monospaced Typefaces (for code) */

/* From http://cssfontstack.com */
code,
.code {
  font-family: Consolas, monaco, monospace;
}

.courier {
  font-family: "Courier Next", courier, monospace;
}

/* Sans-Serif Typefaces */

.helvetica {
  font-family: "helvetica neue", helvetica, sans-serif;
}

.avenir {
  font-family: "avenir next", avenir, sans-serif;
}

/* Serif Typefaces */

.athelas {
  font-family: athelas, georgia, serif;
}

.georgia {
  font-family: georgia, serif;
}

.times {
  font-family: times, serif;
}

.bodoni {
  font-family: "Bodoni MT", serif;
}

.calisto {
  font-family: "Calisto MT", serif;
}

.garamond {
  font-family: garamond, serif;
}

.baskerville {
  font-family: baskerville, serif;
}
